


import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Images
import BgImg from "~images/blog/food3.jpg";

const Privacy = () => {
    const parallax = useRef(null);

    useEffect(() => {
        if (parallax.current) {
            parallax.current.style.backgroundImage = `url(${parallax.current.getAttribute('data-image')})`;
        }
    }, [parallax]);

    return (
		<React.Fragment>
        <section className="page-title valign parallax" data-image={BgImg} ref={parallax}>
			<div className="overlay"></div>	
			<Container>
				<Row>
					<Col className="col-12 text-center">
						<h1 className="blog-title">EatCo Home Customer Privacy Policy</h1>
						<p className="blog-info info">Checkout latest articles</p>

					</Col>
				</Row>
				
			</Container>
		</section>

		<section>
		<Container>
		{/* <Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center pt-5">
							<h3>EatCo Home Customer Privacy Policy </h3>
						</div>
						
					</Col>
				</Row> */}

				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-10">
						
						<div className="section-title2 ">
							<h3>Privacy Policy  </h3>
							<p>
							    EatCo Home Pvt Ltd built the EatCo Home Customer app as free app. This SERVICE is provided by EatCo Home [at no cost] and is intended for use as is. We intend to inform customers regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use our Service. We at EatCo Homes respect you and are committed to honouring and protecting your privacy and the reason we collect your personal data is to improve our services to you. This Customer’s Privacy Notice describes our approach to privacy and our practices regarding why we collect your personal data, what data we are collecting, how we process it, and how you can manage your personal data. Where we determine the purpose and means of processing personal data, we will be a controller. Where we act as a processor in our capacity of providing services to a customer, we will only process personal data in accordance with the instructions of the customer. The customer will be the data controller of that personal data and will be responsible to data subjects for the way in which their personal data is processed. Where applicable, in accordance with data protection laws we shall assist the data controller in effecting data subject rights.
							</p>
							<p className="mrt">
							The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy mean the same as in our Terms and Conditions, which is accessible at EatCo Home Partner unless otherwise defined in this Privacy Policy.
							</p>
							<h3 className="mrt">Information Collection and Use </h3>
							<p>As a general rule, this website/mobile app does not collect personal information about you when you visit the website/mobile app, browse on the site or see information available on the site. You can generally visit the site without revealing personal information, unless you choose to provide such information through “Sign Up” process. </p>
							<p className="mrt">Most of the personal information is collected from you when you sign up for an account on the application to avail the services offered by Eatco. Some more data can be collected when you contact the customer service through calls or e-mail. Information may also be collected in case of adding any favourites or saving any preferences on the application or subscribing to the mailing list.</p>
                            <h4 className="mrt"><b>In order to deliver the best services to you, we may collect the following information:</b></h4>
                            
							<p className="mrt"><b>Account related information: </b> is collected when you sign up for our Services. Your personal data may include information such as Name, Address(es), Phone Number, Email Address, E-mail address and Gender, or other data that could directly or indirectly identify you.</p>
                            <p className="mrt">
								<b>Service Usage Data: </b>is automatically collected when you interact with our website/mobile app. This information may include data about your
								interactions with website/mobile app features, content and links contained in our Services, time of interaction, browser type and configuration, operating system used, IP address, language preferences, and other cookie data. While none of this data will allow us to directly identify who you are, some of this data can be used to approximate your location. For additional information on how to manage the technologies we use, please visit our <b>“cookie policy”</b>.
							</p>
							<p className="mrt">
								<b>Supplementary Data: </b> may be received about you from third party sources, such as public databases or if you sign-up of log-in through third party service provides like Facebook or twitter or email. We may combine this data with the information we already have about you in order to maintain accuracy of our records, and provide services/information that you may be interested in.
							</p>
                            <h3>Information Used</h3>
							<p>We strive to collect the information that is of utmost importance to ensure that the user has a good experience of using the application while all other regulations are complied with. We only collect relevant information after securing the requisite permission from the user when it is needed to deliver the services you have requested, for legal compliance or for any other lawful purpose.</p>
                            <h3 className="mrt">Log Data</h3>
							<p>We want to inform you that whenever you use our Service, in case of an error in the app, we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (IP) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
                            <h3 className="mrt">Cookies</h3>
                            <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
                            <p className="mrt">This Service does not use these cookies explicitly. However, the app may use third party code and libraries that use cookies to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

							<h3 className="mrt">TRANSFERS:</h3>
							<p>We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information would be one of the assets transferred to or acquired by a third party.</p>
                            
							<h3 className="mrt">DATA PROTECTION CONCERN:</h3>
							<p>We maintain your personal data subject to Data Protection Regulation applicable in India. If you have any questions or concerns regarding our Privacy Policy, you may contact us by Email: info@eatcohome.com . We will respond to all requests within 30 days.</p>

							<h3 className="mrt">ACCEPTANCE OF THE POLICY:</h3>
							<p>By visiting our website/mobile app, signing up or logging into the website/mobile app, uploading information on our website/mobile app; you acknowledge and unconditionally accept the policy. If you do not agree with this Policy, do not use our website/mobile app and services or provide any of your personal data.</p>
							
							<h3 className="mrt">Service Providers</h3>
							<p>We may employ third-party companies and individuals due to the following reasons:</p>
							<div  className="section-title1 p-3"><ul>
								<li>To facilitate our Service</li>
								<li>To provide the Service on our behalf</li>
								<li>To perform Service-related services; or </li>
								<li>To provide the Service on our behalf</li>
							</ul></div>

							<p className="mrt">We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
							<h3 >Security </h3>
							<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. </p>




                            <h3>Links to other Sites </h3>
							<p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us.</p>
							<p>Therefore, we strongly advise you to review the Privacy Policy of these websites.</p>
							<p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. </p>
							<h3>Children's Privacy </h3>
							<p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions. </p>
							<h3>Changes to This Privacy Policy </h3>
							<p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. </p>
							<p>This policy is effective as of 2022-04-01. </p>
							<h3>Contact Us </h3>
							<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us. </p>




						
						</div>
						
					</Col>
				</Row>
				
			</Container>
		</section>

		</React.Fragment>
		
		
    );
};

export default Privacy;



















